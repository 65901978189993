import React, { useEffect } from "react"
import {
  ResponsiveContainer,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ComposedChart,
  Cell,
  Tooltip,
  Scatter,
  Legend,
} from "recharts"
import chart_config, { renderLegend } from "../../../../../lib/chart_config"
import { useTheme } from "@aws-amplify/ui-react"
import { useControls } from "../../../../../lib/controls/ControlContext"
import CustomPositionTooltip from "./CustomPositionTooltip"
import _ from "lodash"
import { abbreviate_number } from "../../../../../lib/utils"

const PositionsChart = ({ chart_data, tradeServerMode, chart_field }) => {
  const [chartData, setChartData] = React.useState()
  const { showLabels, showTooltip } = useControls()
  const { tokens } = useTheme()

  const [sortCol, setSortColDir] = React.useState(chart_field)
  const [sortDir, setSortDir] = React.useState("desc")

  useEffect(() => {
    const data = _.orderBy(chart_data, [sortCol], [sortDir])
    setChartData(data)
  }, [tradeServerMode, chart_data, sortCol, sortDir])

  return (
    <ResponsiveContainer height={chart_config.chart_height} debounce={50}>
      <ComposedChart data={chartData}>
        <XAxis
          dataKey="ticker"
          hide={showLabels.isDefault}
          tick={{ stroke: "#888888", strokeWidth: 1 }}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          hide={showLabels.isDefault}
        />
        <YAxis
          yAxisId="left"
          hide={showLabels.isDefault}
          tickFormatter={(tick) =>
            ["AutoRecon", "Positions"].indexOf(tradeServerMode) >= 0
              ? abbreviate_number(tick)
              : tick
          }
        />
        <Legend
          content={renderLegend(() => {
            setSortDir((dir) => (dir === "asc" ? "desc" : "asc"))
            setSortColDir(chart_field)
          })}
        />
        {!!showTooltip.isDefault && (
          <Tooltip
            content={<CustomPositionTooltip chart_field={chart_field} />}
          />
        )}
        <CartesianGrid opacity={tokens.opacities[40]} />
        <Bar
          dataKey={chart_field}
          yAxisId="left"
          fill={tokens.colors.green[60]}
        >
          {chartData?.map((d) => (
            <Cell key={d.ticker} fill={d.bar_color} />
          ))}
        </Bar>
        {tradeServerMode === "AutoRecon" && (
          <Scatter
            dataKey="cum_Contracts_Traded_Diff"
            yAxisId="right"
            fill={tokens.colors.blue[60]}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default PositionsChart
