import { Panel, Table } from "rsuite"
import React, { startTransition, useCallback, useMemo, useState } from "react"
import _ from "lodash"
import "./DataTable.css"
import { CompactFormattedCell, CompactSummaryHeaderCell } from "./DataCell"
const { Column } = Table

const DataTable = React.memo(
  ({
    data = [],
    columns = [],
    totals = {},
    initialSortCol,
    initialSortDir = "desc",
    isLoading,
    isError,
    errorMessage,
    handleRowClick = (x) => x,
    renderRow = (c, r) => c,
  }) => {
    const [sortColumn, setSortColumn] = useState(initialSortCol)
    const [sortType, setSortType] = useState(initialSortDir)
    const [loading, setLoading] = useState(false)

    const handleSortColumn = useCallback((sortColumn, sortType) => {
      setLoading(true)
      startTransition(() => {
        setLoading(false)
        setSortColumn(sortColumn)
        setSortType(sortType)
      })
    }, [])

    const getData = useMemo(() => {
      if (sortType && sortColumn) {
        return _.orderBy(data, [sortColumn], [sortType])
      }
      return data
    }, [data, sortColumn, sortType])

    return (
      <Table
        data={getData}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        onRowClick={handleRowClick}
        renderRow={renderRow}
        loading={isLoading || loading}
        renderEmpty={() => (
          <Panel>{isError ? errorMessage : "No Data Found"}</Panel>
        )}
        headerHeight={50}
        rowHeight={19}
        affixHeader
        affixHorizontalScrollbar
        autoHeight
        className="data-table"
      >
        {columns.map((column) => {
          const {
            field,
            label,
            abbr_label,
            formatFn,
            totals_field,
            totals_format = (rowData, field) => rowData[field],
            cell_comp: CellComp = CompactFormattedCell,
            showMath,
            ...rest
          } = column
          return (
            <Column
              key={field}
              align="right"
              fullText
              verticalAlign="bottom"
              {...rest}
            >
              <CompactSummaryHeaderCell
                totals={totals}
                label={label}
                abbr_label={abbr_label}
                totals_field={totals_field}
                totals_format={totals_format}
              />
              <CellComp formatFn={formatFn} dataKey={field} />
            </Column>
          )
        })}
      </Table>
    )
  }
)

export default DataTable
