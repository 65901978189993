import React from "react"
import { useSearchParams } from "react-router-dom"
import { DateRangePicker, FlexboxGrid } from "rsuite"
import { predefinedRanges } from "../lib/dateRanges"

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = "0" + month
  if (day.length < 2) day = "0" + day

  return [year, month, day].join("-")
}

const DEFAULT_SINCE = "2020-01-01"
const DEFAULT_UNTIL = "2030-01-01"

const usePnlParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  let until_q = searchParams.get("until") || DEFAULT_SINCE
  let since_q = searchParams.get("since") || DEFAULT_UNTIL
  const [dateValues, setDateValues] = React.useState([
    new Date(`${since_q} 01:00:00`),
    new Date(`${until_q} 01:00:00`),
  ])

  const handleRangeChange = (since_new, until_new) => {
    setDateValues([
      new Date(`${since_new} 01:00:00`),
      new Date(`${until_new} 01:00:00`),
    ])
    searchParams.set("until", until_new)
    searchParams.set("since", since_new)
    setSearchParams(searchParams)
  }
  const clearRange = () => {
    setDateValues([
      new Date(`${DEFAULT_SINCE} 01:00:00`),
      new Date(`${DEFAULT_UNTIL} 01:00:00`),
    ])
    setSearchParams(undefined)
  }

  return {
    handleRangeChange,
    clearRange,
    dateValues,
  }
}

const PnLNav = () => {
  const { handleRangeChange, clearRange, dateValues } = usePnlParams()
  const handle_select_dates = (v) => {
    if (v === null) return
    const [since, until] = v
    handleRangeChange(formatDate(since), formatDate(until))
  }
  return (
    <FlexboxGrid>
      <FlexboxGrid.Item>
        <DateRangePicker
          ranges={predefinedRanges}
          onChange={handle_select_dates}
          onClean={clearRange}
          value={dateValues}
        />
      </FlexboxGrid.Item>
    </FlexboxGrid>
  )
}

export default PnLNav
