import React from "react"
import { usePnL } from "../lib/PnLContext"
import { useTheme } from "@aws-amplify/ui-react"
import _ from "lodash"
import HistoricalPnlChart from "./HistoricalPnlChart"
import PnLDrawdownChart from "./PnLDrawdownChart"

const PnlCharts = () => {
  const { data, leverage, leverageToDisplay } = usePnL()
  const { tokens } = useTheme()
  const chart_field = "pctPnlCumulative"
  const chart_data = _.orderBy(data, ["date"], ["asc"]).map((d) => ({
    bar_color: d.official
      ? tokens.colors.metagora.value_color.value(d[chart_field])
      : tokens.colors.metagora.unofficial_blue_chart.value,
    date: d.date,
    pctPnlCumulative: d.pctPnlCumulative * 100,
    leverageCash: d.leverageCash,
    pctDrawdown: d.pctDrawdown,
    portfolioValue: d.portfolioValue,
    pctPnl: d.pctPnl,
  }))
  return (
    <>
      <HistoricalPnlChart
        data={chart_data}
        chart_field={chart_field}
        leverage={leverage}
        leverageToDisplay={leverageToDisplay}
      />
      <PnLDrawdownChart data={chart_data} />
      {/* <pre>{JSON.stringify(data, true, 2)}</pre> */}
    </>
  )
}

export default PnlCharts
