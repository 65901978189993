import React from "react"
import { usePnL } from "../lib/PnLContext"
import DataTable from "../../../../../components/common/DataTable/DataTable"
import { useTheme } from "@aws-amplify/ui-react"

const PnLTable = () => {
  const { data, columns, isLoading } = usePnL()
  const { tokens } = useTheme()

  return (
    <DataTable
      data={data}
      columns={columns}
      initialSortCol="date"
      initialSortDir="desc"
      isLoading={isLoading}
      renderRow={(RowNode, rowData) => (
        <div
          style={{
            height: 19,
            background:
              rowData?.official === false
                ? tokens.colors.metagora.unofficial_blue
                : undefined,
          }}
        >
          {React.cloneElement(RowNode, rowData)}
        </div>
      )}
    />
  )
}

export default PnLTable
