import React from "react"
import { useTheme } from "@aws-amplify/ui-react"
import { Grid, Panel, Row, Col, Stack } from "rsuite"
import { cell_format } from "../../../../../components/common/DataTable/utils"
import { formats, formatString } from "../../../../../lib/utils"

const CustomPositionTooltip = ({ payload, label, active, chart_field }) => {
  const { tokens } = useTheme()
  if (active && payload) {
    const pload = payload[0]?.payload || {}
    const value = pload[chart_field]
    return (
      !!payload[0] && (
        <Panel
          shaded
          bordered
          bodyFill
          style={{
            backgroundColor: tokens.colors.background.primary.value,
            padding: tokens.space.xs,
            fontSize: tokens.fontSizes.xs,
          }}
          header={
            <Stack justifyContent="space-between">
              <div>{label}</div>
              <div>{pload.expiry}</div>
            </Stack>
          }
        >
          <Grid
            fluid
            style={{
              padding: 0,
              margin: 0,
              width: 125,
              whiteSpace: chart_field?.length < 15 ? "nowrap" : undefined,
            }}
          >
            <Row>
              <Col xs={8}>{formatString(chart_field)}</Col>
              <Col xs={16} style={{ textAlign: "right" }}>
                {cell_format((d) =>
                  value?.toString().includes(".")
                    ? formats.pct(label === "percentagePnl" ? d * 100 : d)
                    : formats.currency(d)
                )(value)}
              </Col>
            </Row>
          </Grid>
        </Panel>
      )
    )
  }

  return null
}

export default CustomPositionTooltip
