import React from "react"
import {
  ResponsiveContainer,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ComposedChart,
  Cell,
  ReferenceLine,
  Legend,
  Tooltip,
} from "recharts"
import chart_config, { renderLegend } from "../../../../../lib/chart_config"
import { useTheme } from "@aws-amplify/ui-react"
import { useControls } from "../../../../../lib/controls/ControlContext"
import PnLCustomTooltip from "./PnlCustomTooltip"

const HistoricalPnlChart = ({
  data,
  chart_field,
  leverage,
  leverageToDisplay,
}) => {
  const { tokens } = useTheme()
  const { showLabels } = useControls()

  const CustomToolTip = PnLCustomTooltip({
    data,
    leverage,
    leverageToDisplay,
  })
  const refLineLeftLabel = {
    value: "0.0",
    fill: tokens.colors.metagora.leverage.value,
    position: "left",
    opacity: showLabels.isDefault ? 0 : 1,
  }

  const refLineRightLabel = {
    value: "0.00%",
    fill: tokens.colors.metagora.white.value,
    position: "right",
    opacity: showLabels.isDefault ? 0 : 1,
  }
  const leverageRefLine = {
    value: `Target Leverage ${leverage}`,
    fill: tokens.colors.metagora.target_leverage.value,
    position: "bottom",
    offset: 10,
  }
  return (
    <ResponsiveContainer height={chart_config.chart_height} debounce={50}>
      <ComposedChart
        data={data}
        margin={chart_config.chart_margin}
        syncId="pnl"
      >
        <CartesianGrid strokeDasharray="3 3" opacity={tokens.opacities[40]} />
        <XAxis
          dataKey="date"
          tickFormatter={chart_config.xAxisTick}
          padding={chart_config.xAxisPadding}
          hide={true}
        />
        <YAxis
          yAxisId="left"
          domain={[0, "dataMin+0.1"]}
          tickFormatter={chart_config.yTickFormatter}
          hide={showLabels.isDefault}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          tickFormatter={chart_config.percTickFormatter}
          domain={["dataMax-0.1", "dataMin+0.1"]}
          hide={showLabels.isDefault}
        />
        <Tooltip content={<CustomToolTip />} />
        <Legend content={renderLegend()} verticalAlign="top" />
        <Bar
          yAxisId="right"
          dataKey={chart_field}
          fill={tokens.colors.metagora.green}
        >
          {data.map((d) => (
            <Cell key={d.date} fill={d.bar_color} />
          ))}
        </Bar>
        <Line
          yAxisId="left"
          type="monotone"
          dataKey={leverageToDisplay}
          stroke={tokens.colors.metagora.leverage.value}
          strokeWidth={3}
          dot={false}
          // hide={showBenchmark}
        />
        <ReferenceLine
          isFront={true}
          yAxisId="right"
          y={0}
          stroke={tokens.colors.metagora.chart_axis_stroke}
          label={refLineRightLabel}
          strokeDasharray="3 3"
        />
        <ReferenceLine
          isFront={true}
          yAxisId="left"
          y={0}
          stroke={tokens.colors.metagora.chart_axis_stroke}
          label={refLineLeftLabel}
          strokeDasharray="3 3"
        />
        {leverage && (
          <ReferenceLine
            isFront={true}
            yAxisId="left"
            y={leverage}
            label={leverageRefLine}
            stroke={tokens.colors.metagora.target_leverage.value}
            // hide={showBenchmark}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default HistoricalPnlChart
