import React, { lazy, Suspense, useEffect, useMemo } from "react"
import { FlexboxGrid, Col, Panel, Loader, Message, useToaster } from "rsuite"
import useTradeServerColumns from "./lib/hooks/useTradeServerColumns"
import trade_server_data_config from "./lib/trade_server_data_config"
import { useTradeServer } from "./lib/useTradeServer"
import { useControls } from "../../../../lib/controls/ControlContext"
import { useParams } from "react-router-dom"
import PositionsChart from "./charts/PositionsChart"
import _ from "lodash"
import { useTheme } from "@aws-amplify/ui-react"
import { active_field, ts_row_style } from "./lib/utils"

const TradeServerNav = lazy(() => import("./components/TradeServerNav"))
const DataTable = lazy(() => import("../../../../components/common/DataTable"))

const TradeServerPage = () => {
  const { showTradeServerColors, showChart } = useControls()
  const { managerSlug, portfolioSlug } = useParams()
  const { tokens } = useTheme()
  const { totals, ts_data, summary, ui, isLoading, errors } = useTradeServer(
    managerSlug,
    portfolioSlug
  )
  const { columns, tradeServerMode, all_columns } = useTradeServerColumns(
    trade_server_data_config
  )
  const trade_server_data = tradeServerMode === "Summary" ? summary : ts_data
  const chart_field = active_field(tradeServerMode)

  const chart_data = _.orderBy(
    trade_server_data,
    ["ticker", "expiry"],
    ["asc", "asc"]
  ).map((d) => ({
    bar_color: tokens.colors.metagora.value_color.value(d[chart_field]),
    ...d,
    percentagePnl: d.percentagePnl * 100,
  }))

  const ts_message = useMemo(
    () => (
      <Message type="warning">
        <strong>TradeServer</strong>: {errors.ts_error?.message}
      </Message>
    ),
    [errors.ts_error]
  )
  const toaster = useToaster()

  useEffect(() => {
    if (errors.ts_error) {
      toaster.push(ts_message, { placement: "bottomEnd", duration: 5000 })
    }
  }, [errors.ts_error, toaster, ts_message])

  return (
    <>
      <Suspense fallback={<Loader />}>
        <TradeServerNav
          tradeServerMode={tradeServerMode}
          ui={ui}
          trade_server_data={trade_server_data}
          isLoading={isLoading}
          all_columns={all_columns}
          showVisuals={showTradeServerColors}
          setShowVisual={showTradeServerColors.toggle}
        />
      </Suspense>
      <FlexboxGrid>
        {showChart && (
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} xs={24}>
            <Panel>
              <Suspense fallback={<Loader />}>
                <PositionsChart
                  chart_data={chart_data}
                  tradeServerMode={tradeServerMode}
                  chart_field={chart_field}
                />
              </Suspense>
            </Panel>
          </FlexboxGrid.Item>
        )}
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} xs={24}>
          <Panel>
            <Suspense fallback={<Loader />}>
              <DataTable
                data={trade_server_data}
                columns={columns}
                isLoading={isLoading}
                initialSortCol="ticker"
                initialSortDir="asc"
                totals={totals}
                renderRow={(RowNode, rowData) => (
                  <div
                    style={ts_row_style({
                      showTradeServerColors,
                      tradeServerMode,
                      rowData,
                      totals,
                    })}
                  >
                    {React.cloneElement(RowNode, rowData)}
                  </div>
                )}
              />
            </Suspense>
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  )
}

export default TradeServerPage
