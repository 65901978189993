import React from "react"
import { Stack, Table } from "rsuite"
const { Cell, HeaderCell } = Table

export const CompactCell = (props) => (
  <Cell
    {...props}
    style={{
      padding: 2,
      color: "white",
      height: 15,
      border: "none",
      fontWeight: "bold",
      background: "inherit",
    }}
  />
)

export const CompactFormattedCell = ({ formatFn, dataKey, ...props }) => (
  <CompactCell {...props} dataKey={dataKey}>
    {(rowData) => (!!formatFn ? formatFn(rowData, dataKey) : rowData[dataKey])}
  </CompactCell>
)

export const SummarizeCell = ({ rowData, dataKey, formatFn, ...props }) => {
  const { ids } = rowData
  return (
    <CompactCell {...props} dataKey={dataKey}>
      <Stack justifyContent="space-evenly">
        {ids.map((id, inx) => {
          const value = rowData[`${dataKey}_${inx}`]
          if (value === undefined || value === 0) return null
          return <span key={id}>{formatFn(rowData, `${dataKey}_${inx}`)}</span>
        })}
      </Stack>
    </CompactCell>
  )
}

export const CompactHeaderCell = (props) => (
  <HeaderCell
    {...props}
    style={{
      padding: 2,
    }}
    renderSortIcon={() => null}
  />
)

export const CompactSummaryHeaderCell = ({
  totals,
  label,
  abbr_label,
  totals_field,
  totals_format,
  ...props
}) => (
  <CompactHeaderCell {...props}>
    <Stack
      direction="column"
      spacing={5}
      style={{
        width: "100%",
      }}
      alignItems="flex-end"
      justifyContent="flex-end"
    >
      <div style={{ fontSize: "1.1em" }}>
        {totals[totals_field] !== undefined ? (
          <span>{totals_format(totals[totals_field])}</span>
        ) : (
          <span style={{ opacity: 0 }}>--</span>
        )}
      </div>
      <abbr title={label}>{abbr_label}</abbr>
    </Stack>
  </CompactHeaderCell>
)
