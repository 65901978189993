import React from "react"
import { useTheme } from "@aws-amplify/ui-react"
import { Grid, Panel, Row, Col } from "rsuite"
import leverageRowStyle from "../../HistoricalPNL/lib/leverageRowStyle"
import DateFormat from "../../../../../components/common/DateFormat"
import { formats } from "../../../../../lib/utils"
import { cell_format } from "../../../../../components/common/DataTable/utils"

const valueFormat = (v) => `${v?.toFixed(2)}`

const PnLCustomTooltip = ({ data, leverage, leverageField }) => {
  const { tokens } = useTheme()
  const leverageStyle = { color: tokens.colors.metagora.leverage.value }

  return ({ payload, label, active }) => {
    if (active && payload) {
      const this_data = data.find((d) => d.date === label)
      // console.log(payload, label, this_data)
      return (
        !!this_data && (
          <Panel
            shaded
            bordered
            bodyFill
            style={{
              backgroundColor: tokens.colors.background.primary.value,
              padding: tokens.space.xs,
              fontSize: tokens.fontSizes.xs,
            }}
            header={<DateFormat date={label} format="DD-MMM-YY" />}
          >
            <Grid fluid style={{ padding: 0, margin: 0, width: 125 }}>
              {leverage && (
                <Row>
                  <Col xs={10} style={leverageStyle}>
                    L{` `}[{leverage.toFixed(2)}]
                  </Col>
                  <Col
                    xs={14}
                    style={{
                      textAlign: "right",
                      ...leverageRowStyle(
                        this_data[leverageField],
                        leverage,
                        3
                      ),
                    }}
                  >
                    {valueFormat(this_data[leverageField])}
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={8}>PV</Col>
                <Col xs={16} style={{ textAlign: "right" }}>
                  ${this_data?.portfolioValue?.toLocaleString()}
                </Col>
              </Row>
              <Row>
                <Col xs={8}>DR</Col>
                <Col xs={16} style={{ textAlign: "right" }}>
                  {cell_format(formats.pct)(this_data?.pctPnl)}
                </Col>
              </Row>
              <Row>
                <Col xs={8}>CR</Col>
                <Col xs={16} style={{ textAlign: "right" }}>
                  {cell_format(formats.pct)(this_data[`pctPnlCumulative`])}
                </Col>
              </Row>
              <Row>
                <Col xs={8}>MDD</Col>
                <Col xs={16} style={{ textAlign: "right" }}>
                  {cell_format(
                    (d) => formats.pct(d * 100),
                    true,
                    false,
                    true
                  )(this_data?.pctDrawdown)}
                </Col>
              </Row>
              <Row>
                <Col xs={8}>TTR</Col>
                <Col xs={16} style={{ textAlign: "right" }}>
                  {cell_format(
                    formats.number,
                    true,
                    false,
                    false,
                    true
                  )(this_data?.time_to_recovery)}
                </Col>
              </Row>
              {!this_data?.official && (
                <Row>
                  <Col xs={8}></Col>
                  <Col xs={16} style={{ textAlign: "right" }}>
                    <i>unofficial</i>
                  </Col>
                </Row>
              )}
            </Grid>
          </Panel>
        )
      )
    }

    return null
  }
}

export default PnLCustomTooltip
