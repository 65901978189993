import { generateClient } from "aws-amplify/api"
import { metagoraPortfolioPnLData } from "../../../../../graphql/queries"

// const REFETCH_INTERVAL = 1000 * 60 * 5

const client = generateClient()

const GraphQL_API = (query, variables = {}) => {
  return client.graphql({ query, variables })
}

const getPnLData = async (input) => {
  //PnL pnLQuery
  //   console.log(input)
  try {
    const { data } = await GraphQL_API(metagoraPortfolioPnLData, { input })
    return data.metagoraPortfolioPnLData
  } catch (e) {
    console.log(e)
    throw e.errors[0]
  }
}

export default getPnLData

export const pnlQuery = (
  exchange,
  account,
  since = "2020-01-01",
  until = "2030-01-01"
) => ({
  queryKey: [exchange, account, since, until],
  queryFn: () => getPnLData({ exchange, account, since, until }),
  enabled: !!exchange && !!account,
})
