import React, { useContext } from "react"
import { useQuery } from "@tanstack/react-query"
import pnl_table_config from "./pnl_table_config"
import { pnlQuery } from "./getPnLData"
import { useSearchParams } from "react-router-dom"

let PnLContext
const { Provider, Consumer } = (PnLContext = React.createContext())

const PnLProvider = ({ portfolio, children }) => {
  const { broker: exchange, accountNum: account } = portfolio?.account
    ? portfolio?.account[0]
    : {}
  const [searchParams] = useSearchParams()
  const { data, isLoading } = useQuery({
    ...pnlQuery(
      exchange,
      account,
      searchParams.get("since") || undefined,
      searchParams.get("until") || undefined
    ),
  })
  const columns = pnl_table_config({
    leverageToDisplay: portfolio.leverageToDisplay,
    leverage: portfolio.metaData.leverageSetting,
    compact: true,
  })
  return (
    <Provider
      value={{
        summary: data?.summary,
        data: data?.data,
        leverage: portfolio.metaData.leverageSetting,
        leverageToDisplay: portfolio.leverageToDisplay,
        isLoading,
        columns,
      }}
    >
      {children}
    </Provider>
  )
}

export { PnLContext, Consumer as PnLConsumer, PnLProvider }

export const usePnL = () => {
  const context = useContext(PnLContext)
  return context
}
