import {
  cell_format,
  style_by_value,
} from "../../../../../components/common/DataTable/utils"
import { formats } from "../../../../../lib/utils"
import { Tooltip, Whisper } from "rsuite"
import leverageRowStyle from "../../HistoricalPNL/lib/leverageRowStyle"
import { Icon } from "@rsuite/icons"
import { RiAlertFill } from "react-icons/ri"

const pnl_table_config = ({ leverageToDisplay, leverage, compact }) => [
  {
    label: "Date",
    abbr_label: "D",
    field: "date",
    formatFn: (rowData, dataKey) => formats.date("DD-MMM-YY")(rowData[dataKey]),
    width: 70,
    fixed: true,
    sortable: true,
  },
  {
    label: "Portfolio Value",
    abbr_label: "PV",
    field: "portfolioValue",
    formatFn: (rowData, dataKey) => (
      <span style={{ position: "relative" }}>
        {rowData.deposit > 0 ? (
          <Whisper
            speaker={
              <Tooltip
                placement="top"
                trigger="hover"
                style={{
                  color: "rgba(4, 255, 4, .9)",
                  backgroundColor: "rgb(50,50,50)",
                  fontWeight: "bold",
                }}
              >
                {compact
                  ? formats.us_abbr(rowData.deposit)
                  : formats.currency(rowData.deposit)}{" "}
                Deposit
              </Tooltip>
            }
          >
            <span
              style={{
                color: "rgba(4, 255, 4, .9)",
                position: "absolute",
                left: -15,
              }}
            >
              D
            </span>
          </Whisper>
        ) : null}
        {rowData.deposit < 0 ? (
          <Whisper
            speaker={
              <Tooltip
                placement="top"
                trigger="hover"
                style={{
                  color: "rgba(255, 80, 0, .9)",
                  backgroundColor: "rgb(50,50,50)",
                  fontWeight: "bold",
                }}
              >
                {compact
                  ? formats.us_abbr(rowData.deposit)
                  : formats.currency(rowData.deposit)}{" "}
                Withdraw
              </Tooltip>
            }
          >
            <span
              style={{
                color: "rgba(255, 80, 0, .9)",
                position: "absolute",
                left: -15,
              }}
            >
              W
            </span>
          </Whisper>
        ) : null}
        {compact
          ? formats.us_abbr(rowData[dataKey])
          : formats.currency(rowData[dataKey])}
      </span>
    ),
    width: compact ? 65 : 100,
    sortable: true,
  },
  {
    label: "Daily PNL",
    abbr_label: "D$",
    field: "rawPnl",
    formatFn: cell_format(compact ? formats.us_abbr : formats.currency),
    width: compact ? 55 : 85,
    sortable: true,
  },
  {
    label: "Cumulative PNL",
    abbr_label: "C$",
    field: "rawPnlCumulative",
    formatFn: cell_format(compact ? formats.us_abbr : formats.currency),
    width: compact ? 55 : 90,
    sortable: true,
  },
  {
    label: "Daily Return",
    abbr_label: "DR",
    field: "pctPnl",
    formatFn: cell_format((d) => formats.pct(d * 100)),
    width: 50,
    sortable: true,
  },
  {
    label: "Cumulative Return",
    abbr_label: `CR`,
    field: "pctPnlCumulative",
    formatFn: cell_format((d) => formats.pct(d * 100)),
    width: 50,
    sortable: true,
  },
  {
    label: "Return Cash",
    abbr_label: "RC",
    field: "returnCash",
    formatFn: cell_format((d) => formats.pct(d * 100)),
    width: 50,
    sortable: true,
  },
  {
    label: "Cumulative Return Cash",
    abbr_label: "CRC",
    field: "returnCashCumulative",
    formatFn: cell_format((d) => formats.pct(d * 100)),
    sortable: true,
    width: 50,
  },
  {
    label: "Commissions",
    abbr_label: "C",
    field: "commisions",
    formatFn: cell_format(formats.currency),
    sortable: true,
    width: 60,
  },
  {
    label: "Commissions Cumulative",
    abbr_label: "CC",
    field: "commisionsCumulative",
    formatFn: cell_format(formats.currency),
    sortable: true,
    width: 60,
  },
  {
    label: "Max Drawdown",
    abbr_label: "MDD",
    field: "pctDrawdown",
    formatFn: cell_format((d) => formats.pct(d * 100), true, false, true),
    width: 50,
    sortable: true,
  },
  {
    label:
      leverageToDisplay === "REALIZED_LEVERAGE"
        ? "Realized Leverage"
        : leverageToDisplay === "LEVERAGE_CASH"
        ? "Leverage"
        : "Leverage",
    abbr_label:
      leverageToDisplay === "REALIZED_LEVERAGE"
        ? "RL"
        : leverageToDisplay === "LEVERAGE_CASH"
        ? "L"
        : "L",
    field:
      leverageToDisplay === "REALIZED_LEVERAGE"
        ? "realizedLeverage"
        : leverageToDisplay === "LEVERAGE_CASH"
        ? "leverageCash"
        : "leverage",
    format: "leverage",
    formatFn: style_by_value(formats.float_2, (value) =>
      leverageRowStyle(value, leverage, 3)
    ),
    width: 35,
  },
  {
    label: "",
    abbr_label: "",
    field: "official",
    formatFn: (r, f) =>
      r[f] ? (
        ""
      ) : (
        <Whisper
          speaker={
            <Tooltip
              placement="top"
              trigger="hover"
              style={{
                fontWeight: "bold",
              }}
            >
              Unofficial
            </Tooltip>
          }
        >
          <span>
            <Icon as={RiAlertFill} />
            {` `}
            {compact ? "" : "unofficial"}
          </span>
        </Whisper>
      ),
    width: compact ? 30 : 70,
  },
]

export default pnl_table_config
