import React, { Suspense } from "react"
import { PnLProvider } from "./lib/PnLContext"
import { Col, Grid, Loader, Row } from "rsuite"
import PnLTable from "./components/PnLTable"
import { useOutletContext } from "react-router-dom"
import PnLNav from "./components/PnLNav"
import PnlCharts from "./charts/PnLCharts"

const PnLPage = () => {
  const { portfolio } = useOutletContext()
  // console.log(portfolio)
  return (
    <PnLProvider portfolio={portfolio}>
      <PnLNav />
      <Grid fluid>
        <Row>
          <Col xs={24}>
            <Suspense fallback={<Loader />}>
              <PnlCharts />
            </Suspense>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Suspense fallback={<Loader />}>
              <PnLTable />
            </Suspense>
          </Col>
        </Row>
      </Grid>
    </PnLProvider>
  )
}

export default PnLPage
