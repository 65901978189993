import React from "react"
import {
  ResponsiveContainer,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ComposedChart,
  Cell,
  Legend,
} from "recharts"
import chart_config, { renderLegend } from "../../../../../lib/chart_config"
import { useTheme } from "@aws-amplify/ui-react"
import { useControls } from "../../../../../lib/controls/ControlContext"

const PnLDrawdownChart = ({ data }) => {
  const { tokens } = useTheme()
  const { showLabels } = useControls()
  const barDrawDownCell = (d, i) => (
    <Cell key={d.date} fill={tokens.colors.metagora.drawdown_pct} />
  )
  return (
    <ResponsiveContainer height={chart_config.chart_height / 2} debounce={50}>
      <ComposedChart
        data={data}
        margin={{
          top: 0,
          right: 15,
          left: 0,
          bottom: 5,
        }}
        syncId="pnl"
      >
        <CartesianGrid strokeDasharray="3 3" opacity={tokens.opacities[40]} />
        <XAxis
          dataKey="date"
          tickFormatter={chart_config.xAxisTick}
          padding={chart_config.xAxisPadding}
          hide={showLabels.isDefault}
        />
        <YAxis
          yAxisId="left"
          domain={["dataMin", "dataMax"]}
          hide={showLabels.isDefault}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          tickFormatter={(tick) => `${tick.toFixed(2)}%`}
          domain={["dataMin", "dataMax"]}
          hide={showLabels.isDefault}
        />
        <Legend content={renderLegend} />
        <Bar
          yAxisId="right"
          dataKey="pctDrawdown"
          fill={tokens.colors.teal[20]}
        >
          {data.map(barDrawDownCell)}
        </Bar>
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default PnLDrawdownChart
