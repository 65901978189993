import { useQuery } from "@tanstack/react-query"
import _ from "lodash"
import { metagoraPortfolioPositionData } from "./graphql"
import { GraphQL_API } from "./utils"

const REFRESH_INTERVAL = 1000 * 60 * 5

export const getPositionData = async (managerSlug, portSlug) => {
  try {
    const response = await GraphQL_API(metagoraPortfolioPositionData, {
      input: {
        portSlug,
        managerSlug,
      },
    })
    return response.data.metagoraPortfolioPositionData
  } catch (error) {
    if (error.data.metagoraPortfolioPositionData) {
      return error.data.metagoraPortfolioPositionData
    } else {
      throw new Error("No Portfolio Positions")
    }
  }
}

const sumFields = (items, fields) =>
  fields.reduce(
    (acc, field) => {
      acc[`total_${field}`] = _.sumBy(items, field)
      return acc
    },
    {
      position_diff_abs: _.sumBy(
        items,
        (o) => Math.abs(o.positionDifference) || 0
      ),
      cum_abs_traded_diff: _.sumBy(
        items,
        (o) => Math.abs(o.cumContractsTradedDiff) || 0
      ),
    }
  )

const tradeServerSummary = (instrument_pnls) => {
  const grouped = _.groupBy(instrument_pnls, "ticker")
  return Object.values(grouped).map((instrument) =>
    instrument.reduce(
      (tot, item) => {
        const fields = [
          "relative_pnl",
          "absolute_pnl",
          "maintenance_margin_relative",
          "maintenance_margin",
          "margin_per_contract",
        ]

        fields.forEach((field) => {
          tot[field] += item[field] || 0
        })
        return {
          ...tot,
          id: `${tot.id}_${item.id}`,
          leg_count: tot.leg_count + 1,
          ticker: item.ticker,
          endQuantity: item.endQuantity,
        }
      },
      {
        id: instrument[0].ticker,
        leg_count: 0,
        relative_pnl: 0,
        absolute_pnl: 0,
        maintenance_margin_relative: 0,
        maintenance_margin: 0,
        margin_per_contract: 0,
      }
    )
  )
}

export const positionsQuery = ({ portfolioSlug, managerSlug }) => ({
  queryKey: [managerSlug, portfolioSlug, "positions"],
  queryFn: async () => await getPositionData(managerSlug, portfolioSlug),
  enabled: Boolean(portfolioSlug && managerSlug),
  refetchInterval: REFRESH_INTERVAL,
})

export const useTradeServer = (managerSlug, portfolioSlug) => {
  const {
    data: positionData,
    isLoading: p_isLoading,
    error: p_error,
  } = useQuery({
    ...positionsQuery({ portfolioSlug, managerSlug }),
    select: (portfolio) => {
      const {
        slug,
        id,
        account: accounts,
        positionsEndpoint,
        lastSync,
        positions = {},
        autoRecon = [],
      } = portfolio || {}
      const account = accounts ? accounts[0].accountNum : null
      const exchange = accounts ? accounts[0].broker : null
      const { data: position_data } = positions

      const ui = {
        portfolio_id: id,
        lastSync,
        hasAutoRecon: Boolean(autoRecon?.length),
        portName: slug,
        endpoint: positionsEndpoint,
      }

      const auto_positions = autoRecon?.length
        ? autoRecon
            .map((cmd) => {
              const {
                name,
                instrumentID,
                modelPosition,
                accountPosition,
                positionDifference,
                cumModelContractsTraded,
                cumAccountContractsTraded,
                cumContractsTradedDiff,
              } = cmd
              const [symbol_root, cmd_exp] = name.split("_")
              const pos = position_data.find(
                (p) => p.expiry === cmd_exp && p.symbol_root === symbol_root
              ) || {
                no_position: true,
              }
              return {
                id: pos.instrument_id || instrumentID,
                ticker: pos.symbol_root || symbol_root,
                expiry: pos.expiry || cmd_exp,
                startQuantity: pos.quantity,
                endQuantity: pos.quantity,
                size: Math.abs(pos.quantity),
                endPriceRaw: pos.price,
                value: +(pos.quantity * pos.price * pos.multiplier).toFixed(),
                position_value: pos.position_value,
                absolute_pnl: pos.absolute_pnl,
                relative_pnl: pos.relative_pnl,
                priceDiff: pos.price_diff,
                priceChange: pos.price_change,
                initial_margin: pos.initial_margin,
                maintenance_margin: pos.maintenance_margin,
                margin_per_contract: pos.margin_per_contract,
                maintenance_margin_relative: pos.maintenance_margin_relative,
                modelPosition,
                accountPosition,
                positionDifference,
                cumModelContractsTraded,
                cumAccountContractsTraded,
                cumContractsTradedDiff,
                changed: Math.abs(cumContractsTradedDiff) >= 1,
                different: Math.abs(positionDifference) >= 1,
                cum_Contracts_Traded_Diff: Math.abs(cumContractsTradedDiff),
              }
            })
            .sort(
              (a, b) => a.ticker?.localeCompare(b.ticker) || a.expiry - b.expiry
            )
        : position_data
            .map((pos) => {
              return {
                id: pos.instrument_id,
                ticker: pos.symbol_root,
                expiry: pos.expiry,
                value: +(pos.quantity * pos.price * pos.multiplier).toFixed(),
                position_value: pos.position_value,
                absolute_pnl: pos.absolute_pnl,
                relative_pnl: pos.relative_pnl,
                startPriceRaw: pos.price,
                priceDiff: pos.price_diff,
                priceChange: pos.price_change,
                startQuantity: pos.quantity,
                endQuantity: pos.quantity,
                size: Math.abs(pos.quantity),
                initial_margin: pos.initial_margin,
                maintenance_margin: pos.maintenance_margin,
                margin_per_contract: pos.margin_per_contract,
                maintenance_margin_relative: pos.maintenance_margin_relative,
              }
            })
            .sort(
              (a, b) => a.ticker?.localeCompare(b.ticker) || a.expiry - b.expiry
            )

      return {
        auto_positions,
        account,
        exchange,
        ui,
      }
    },
  })

  const { auto_positions, account, exchange } = positionData || {}

  return {
    isLoading: p_isLoading,
    ui: positionData?.ui,
    instruments: [],
    accountInfo: { account, exchange },
    ts_data: auto_positions,
    errors: { p_error },
    summary: tradeServerSummary(auto_positions),
    totals: sumFields(auto_positions, [
      "position_value",
      "absolute_pnl",
      "relative_pnl",
      "maintenance_margin_relative",
      "maintenance_margin",
    ]),
  }
}
